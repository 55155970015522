import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { one, two, three, four, five, six } from "../assets";

export default function HeroSlider() {
  return (
    <div className="carousel-wrapper">
      <Carousel infiniteLoop useKeyboardArrows autoPlay interval={7000}>
        <div>
          <img className="max-h-50" alt="image1" src={one} />
        </div>
        <div>
          <img className="max-h-50" src={two} alt="image2" />
        </div>
        <div>
          <img className="max-h-50" src={three} alt="image3" />
        </div>
        <div>
          <img className="max-h-50" src={four} alt="image4" />
        </div>
        <div>
          <img className="max-h-50" src={five} alt="image5" />
        </div>
        <div>
          <img className="max-h-50" src={six} alt="image6" />
        </div>
      </Carousel>
    </div>
  );
}
