import React, { useEffect } from "react";
import styles from "../style";
import ReactGA from 'react-ga';

function About() {

  const TRACKING_ID = "G-TN1N98RRNV";
  ReactGA.initialize(TRACKING_ID);
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
    ReactGA.pageview(window.location.search);
  },[])
  return (
    <div className="flex flex-col w-full bg-white">
      <section
        className={`${styles.flexCenter} my-5 ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}
      >
        <div className="flex-1 flex flex-col">
          <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-4xl">
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
              About the Mining Revenue Monitoring Index
            </span>
          </h1>
        </div>
      </section>
      <div className="items-center justify-center m-4">
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-3xl lg:text-3xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
            Background
          </span>
        </h1>
        <p className="first-letter:text-7xl first-letter:font-bold first-letter:text-gray-900 dark:first-letter:text-gray-100 first-letter:mr-3 first-letter:float-left font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
          The extractive sector in Africa remains a lucrative space for
          investment and for many countries, it is seen as a launchpad to
          advance local and national development. However, in as much as there
          is this potential for the extractive industry, it is an area that is
          fraught with many challenges that have limited its contribution to
          advancing the continent's development.
        </p>
        <br></br>
        <p className="font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
          Zimbabwe has an abundance and variety of mineral resources. The
          Government has developed a strategic roadmap -
          <a
            href="https://www.herald.co.zw/the-minerals-of-hope-us12bn-mining-sector-target-to-change-nations-fortunes/"
            target="_blank"
            rel="noreferrer"
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            The Herald: The minerals of hope - US$12bn mining sector target to change nations fortunes
          </a>{" "}
          to work with private players to make the sector a US12 billion dollar
          industry by 2023. However, global, and local studies have demonstrated
          that the enclave-like form of development that mining companies can
          develop. In addition, there has been limited transparency in the
          sector around revenues. There is limited developmental evidence to
          demonstrate that Zimbabwe is well endowed with valuable mining
          resources.
        </p>
        <br></br>
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-3xl lg:text-3xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
            Mining and Development
          </span>
        </h1>
        <p className="font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
          Mining’s contribution towards Zimbabwe’s Gross Domestic Product (GDP)
          has always hovered around 15%. However, the contribution of mining
          towards national development has not been adequately measured beyond
          the GDP portion measure. In addition, measuring the contribution to
          GDP alone is not enough. There is a need to measure the contribution
          made by mining towards Gross National Income (GNI). The GNI is the
          amount that remains within the country after payments to foreign
          shareholders and other licences have been paid. Several studies
          carried out by organisations such as AFRODAD, Transparency Zimbabwe,
          ZELA and ZIMCODD suggest that there are high levels of corruption and
          leakages in terms of revenue retention. Other literature suggests that
          mining should not only be seen as a significant contributor to GDP but
          also potentially the largest contributor to towards to the portion of
          resources the country loses through a phenomenon known as illicit
          financial flows. (Illicit financial flows is where revenues are
          siphoned out of the country through trade mis-invoicing, tax evasion
          and under declaration of proceeds from exports.). It is estimated by
          the Institute for Financial Integrity that commercial tax evasion
          amongst mining firms mainly through trade mispricing comprises 60-65%
          of the total leakages.
        </p>{" "}
        <br></br>
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-3xl lg:text-3xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
            So why the Mining Revenue Monitoring Index (MRMI)
          </span>
        </h1>
        <p className="font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
          The MRMI is a one-stop platform that seeks to provide information
          about revenues in Zimbabwe’s mining sector. With time the platform
          will be expanded to include other countries. There is no single
          publicly available repository of consolidated information that is
          accessible to anyone interested in finding information on how mining
          entities in Zimbabwe – private and state-owned are performing. This
          has also limited the capacity of civil society and communities to have
          data that allows them to actively engage with government and mining
          companies for the advancement of more responsive policies to enhance
          transparency and tax effectiveness to improve the contribution of
          mining to community and national development.
        </p>
        <br></br>
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-3xl lg:text-3xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
            Sourcing of Information
          </span>
        </h1>
        <p className="font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
          The data used on this platform has been sourced and collated from
          different platforms. These include:{" "}
        </p>
        <ul className="mb-8 space-y-4 text-left">
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-5 h-5 text-[#7FBC41] dark:text-[#7FBC41]"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
              Annual Financial Reports from the official websites of companies
            </span>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-5 h-5 text-[#7FBC41] dark:text-[#7FBC41]"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
              Newspaper Articles/Reports of mining companies' performance
            </span>
          </li>
          <li className="flex items-center space-x-3">
            <svg
              className="flex-shrink-0 w-5 h-5 text-[#7FBC41] dark:text-[#7FBC41]"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]">
              The Zimbabwe Chamber of Mines (2021) Data
            </span>
          </li>
        </ul>
        <h1 className="mb-4 text-xl font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-[red] from-[red]">
            NB:{" "}
          </span>
          <span>
            Any gaps in the data is because the information could not be
            obtained or found online. Should the tracker be missing any data,
            please contact{" "}
            <a className="text-[#7FBC41]" href="mailto:info@sivioinstitute.org">
              info@sivioinstitute.org
            </a>{" "}
            to share the data and we will update the tracker accordingly after
            an internal verification process.{" "}
          </span>
        </h1>
      </div>
    </div>
  );
}

export default About;
