import React, {useEffect} from "react";
import Minerals from "./minerals/Minerals";
import Grossrevenue from "./GrossRevenues/Grossrevenue";
import Netprofit from "./NetProfits/Netprofit";
import Oreoutput from "./OreOutputs/Oreoutput";
import TotalTax from "./TotalTaxes/TotalTax";
import Csr from "./CSR/Csr";
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";

function Dashboard() {

  const TRACKING_ID = "G-TN1N98RRNV";
  ReactGA.initialize(TRACKING_ID);

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
    ReactGA.pageview(window.location.search);
  },[])

  return (
    <div>
      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-cta"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  to="/mineral-output"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Mineral Output
                </Link>
              </li>
              <li>
                <Link
                  to="/gross-revenue"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Gross Revenue
                </Link>
              </li>
              <li>
                <Link
                  to="/net-profit"
                  className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-[#7fbc41] md:p-0 md:dark:text-blue-500"

                >
                  Net Profit
                </Link>
              </li>
              <li>
                <Link
                  to="/total-taxes"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Total Tax
                </Link>
              </li>
              <li>
                <Link
                  to="/csr"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  CSR
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section id="mineral">
        <Minerals />
      </section>
      <section id="oreoutput">
        <Oreoutput />
      </section>
      <section id="grossrevenue">
        <Grossrevenue />
      </section>
      <section id="netprofit">
        <Netprofit />
      </section>
      <section id="totaltax">
        <TotalTax />
      </section>
      <section id="csr">
        <Csr />
      </section>
      <iframe title="MRMI Report" width="600" height="373.5" src="https://app.powerbi.com/view?r=eyJrIjoiNzEyZDgxM2YtODY0ZS00MDIyLWE0Y2ItMmIzNGUwYjJmOWMzIiwidCI6IjQxN2I5MjBkLTc4N2ItNDlhMi1iYTEzLTdkMDlmOGJiNTJkZiJ9" frameborder="0" allowFullScreen="true"></iframe>
    </div>
  );
}

export default Dashboard;
