import React, {useState, useEffect} from 'react';
import Papa from 'papaparse';
import '../../css/Minerals.css'
import styles from '../../style';
import PlatinumTotalTax from './PlatinumTotalTax';
import DiamondTotalTax from './DiamondTotalTax';
import ChromeTotalTax from './ChromeTotalTax';
import GoldTotalTax from './GoldTotalTax';

function TotalTax() {

const [toggleState, setToggleState] = useState(1);
const toggleTab = (index) => {
  setToggleState(index);

  if(index===1){
    fetchData()
  }else if(index===2){
    fetchPlatinum()
  }else if(index===3){
    fetchDiamond()
  }else{
    fetchChrome()
  }

};

useEffect(()=>{
  fetchData()
},[])



const [parsedCsvData, setParsedCsvData] = useState([]);
const [parsedDiamondData, setParsedDiamondData] = useState([]);
const [parsedPlatinumData, setParsedPlatinumData] = useState([]);
const [parsedChromeData, setParsedChromeData] = useState([]);
const fetchData = () => {
  Papa.parse(`https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/gold%20total%20sum.csv`, {
    header: true,
      download: true,
      skipEmptyLines: true,
    complete: results => {
      setParsedCsvData(results.data)      
    },
  });
  //console.log(parsedCsvData)
  
};

const fetchPlatinum = () => {
  Papa.parse(`https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/platinum%20total%20sum.csv`, {
    header: true,
      download: true,
      skipEmptyLines: true,
    complete: results => {
      setParsedPlatinumData(results.data)      
    },
  });
  //console.log(parsedPlatinumData)
  
};

const fetchDiamond = () => {
  Papa.parse(`https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/diamond%20total%20sum.csv`, {
    header: true,
      download: true,
      skipEmptyLines: true,
    complete: results => {
      setParsedDiamondData(results.data)      
    },
  });
  //console.log(parsedDiamondData)
  
};

const fetchChrome = () => {
  Papa.parse(`https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/chrome%20total%20sum.csv`, {
    header: true,
      download: true,
      skipEmptyLines: true,
    complete: results => {
      setParsedChromeData(results.data)      
    },
  });
  //console.log(parsedChromeData)
  
};

  return (
    <div id="chart" className='bg-white'>
      <p className={`${styles.paragraph} pt-20 pb-10`}>
            <span className="text-black text-3xl">Total Tax</span> 
  </p>
          <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            Gold
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Platinum
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            Diamond
          </button>
          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(4)}
          >
            Chrome
          </button>
        </div>

        <div className="content-tabs">
          <div
            className={toggleState === 1 ? "content  active-content" : "content"}
          >
            <h2>Gold</h2>
            <hr />
            <GoldTotalTax parsedCsvData={parsedCsvData} />
          </div>
  
          <div
            className={toggleState === 2 ? "content  active-content" : "content"}
          >
            <h2>Platinum</h2>
            <hr />
            <PlatinumTotalTax parsedPlatinumData={parsedPlatinumData}/>
          </div>
  
          <div
            className={toggleState === 3 ? "content  active-content" : "content"}
          >
            <h2>Diamond</h2>
            <hr />
            <DiamondTotalTax parsedDiamondData={parsedDiamondData} />
          </div>

          <div
            className={toggleState === 4 ? "content  active-content" : "content"}
          >
            <h2>Chrome</h2>
            <hr />
            <ChromeTotalTax parsedChromeData={parsedChromeData}/>
          </div>
        </div>

</div>

  )
}

export default TotalTax















