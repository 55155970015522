import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ styles }) => {

  const navigation = useNavigate();

  return (
  <button type="button" className={`py-4 px-6 font-poppins font-medium text-[18px] text-primary bg-[#7FBC41] rounded-[10px] outline-none ${styles}`} onClick={() => {navigation(`/report`)}}>
    Reports
  </button>
);
}

export default Button;
