import styles from "../style";
import { Platinum, zero } from "../assets";
import GetStarted from "./GetStarted";
import Stats from "./Stats";
import CTA from "./CTA";
import HeroSlider from "./HeroSlider";
import React, {useEffect} from "react";
import ReactGA from 'react-ga';


const Hero = () => {
  const TRACKING_ID = "G-TN1N98RRNV";
  ReactGA.initialize(TRACKING_ID);
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
    ReactGA.pageview(window.location.search);
  },[])
  return (
    <div className="bg-primary w-full overflow-hidden">

      
      {/*<Slider/>*/}
    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold ss:text-[62px] text-[42px] text-[#F15B26] ss:leading-[100.8px] leading-[75px]">
            The Mining <br className="sm:block hidden" />{" "}
            <span className="text-[#F15B26]">Revenue Monitoring Index</span>{" "}
          </h1>
                  </div>

        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        The Mining Revenue Monitoring Index (MRMI) tracks the operations of mining companies
         in Zimbabwe focusing on mineral output, gross revenues, profits, taxes and corporate
          social responsibility (CSR) since 2017. The initial focus of the index is on
           local and international companies mining diamonds, chrome, gold and platinum.   
        </p>
      </div>



<img className="rounded-full mr-10 w-96 h-96 transition ease-in-out hover:-translate-x-1 hover:scale-110 duration-300 bg-inherit" src={zero} alt="image description"/>

<img className="rounded-full mr-10 w-64 h-64 transition ease-in-out hover:-translate-x-1 hover:scale-110 duration-300" src={Platinum} alt="image description"/>



    </section>

    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
      
    <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 mx-10 relative max-h-50`}>
      <HeroSlider/>
      </div>
      
      <div className={`xl:px-0 sm:px-16 px-6`}>
      

        <div className="flex flex-col justify-between items-center w-full">
          <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          The MRMI initially tracks the production of 10 mining companies across four (4) key minerals to enhance the understanding
           of the financial contribution of the mining sector to Zimbabwe’s economic and social development. For more details on
            and the background to the MRMI go to:
            </p>
            <div className={``}>
              <GetStarted />
            </div> 
         
        </div>
      </div>

    
     
    </section>




    
        
    

    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Stats />
        <CTA />
      </div>
    </div>
    </div>
    
  );
};

export default Hero;
