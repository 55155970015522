import React, { useState, useEffect } from "react";
import "../../css/Minerals.css";
import styles from "../../style";
import GoldDatagrid from "./GoldDatagrid";
import DiamondDatagrid from "./DiamondDatagrid";
import ChromeDatagrid from "./ChromeDatagrid";
import PlatinumDatagrid from "./PlatinumDatagrid";
import Papa from "papaparse";
function Minerals() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);

    if (index === 1) {
      fetchData();
    } else if (index === 2) {
      fetchPlatinum();
    } else if (index === 3) {
      fetchDiamond();
    } else {
      fetchChrome();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [parsedCsvData, setParsedCsvData] = useState([]);
  const [parsedDiamondData, setParsedDiamondData] = useState([]);
  const [parsedPlatinumData, setParsedPlatinumData] = useState([]);
  const [parsedChromeData, setParsedChromeData] = useState([]);
  const fetchData = () => {
    Papa.parse(
      `https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/gold%20total%20sum.csv`,
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedCsvData(results.data);
        },
      }
    );
    //console.log(parsedCsvData)
  };

  const fetchPlatinum = () => {
    Papa.parse(
      `https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/platinum%20total%20sum.csv`,
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedPlatinumData(results.data);
        },
      }
    );
    //console.log(parsedPlatinumData)
  };

  const fetchDiamond = () => {
    Papa.parse(
      `https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/diamond%20total%20sum.csv`,
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedDiamondData(results.data);
        },
      }
    );
    //console.log(parsedDiamondData)
  };

  const fetchChrome = () => {
    Papa.parse(
      `https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/chrome%20total%20sum.csv`,
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedChromeData(results.data);
        },
      }
    );
    //console.log(parsedChromeData)
  };

  /*
    useEffect(() => {
   fetchData()
    //fetchPlatinum()
      }, [])*/

  return (
    <div>
      <div className="w-full bg-white pt-10 pb-10">
        <section
          className={`${styles.flexCenter} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}
        >
          <div className="flex-1 flex flex-col">
            <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-4xl lg:text-4xl">
              <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
                Companies Overview
              </span>
            </h1>
          </div>
        </section>
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            Gold
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Platinum
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            Diamond
          </button>
          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(4)}
          >
            Chrome
          </button>
        </div>

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <h2>Gold</h2>
            <hr />
            <GoldDatagrid parsedCsvData={parsedCsvData} />
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <h2>Platinum</h2>
            <hr />
            <PlatinumDatagrid parsedPlatinumData={parsedPlatinumData} />
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <h2>Diamond</h2>
            <hr />
            <DiamondDatagrid parsedDiamondData={parsedDiamondData} />
          </div>

          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
            <h2>Chrome</h2>
            <hr />
            <ChromeDatagrid parsedChromeData={parsedChromeData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Minerals;
