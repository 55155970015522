import styles from "../style";
import { useNavigate } from "react-router-dom";

const GetStarted = () => {

  const navigation = useNavigate();
  
  return(
    <div className={`${styles.flexCenter} w-[140px] h-[140px] rounded-full p-[2px] cursor-pointer`}>
      <div className={`${styles.flexCenter} flex-col bg-[#7FBC41] w-96 h-16 rounded-10 hover:bg-[#F15B26] hover:text-white transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300`} onClick={() => {navigation(`/dashboard`)}}>
        <div className={`${styles.flexStart} flex-row`}>
          <div className="font-poppins font-medium text-[18px] leading-[23.4px]">
            <span className="text-gradient">Learn more</span>
          </div>
      
        </div>
        
      </div>
    </div>
  );
}

export default GetStarted;
