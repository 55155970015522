import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { Link, useParams } from "react-router-dom";
import styles from "../style";
import ReactGA from 'react-ga';
import { back } from "../assets";

function Detail() {
  const [parsedData, setParsedData] = useState([]);
  let specificData = [];
  let { id } = useParams();
  let name = "";
  let mineralName = "";
  const TRACKING_ID = "G-TN1N98RRNV";
  ReactGA.initialize(TRACKING_ID);
  
  const fetchData = () => {
    Papa.parse(
      `https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/MRMI%20all%20data.csv`,
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedData(results.data.reverse());
        },
      }
    );
  };

  parsedData.forEach((staffDetail) => {
    if (staffDetail.key === id) {
      specificData.push(staffDetail);
      console.log(staffDetail);
    }
  });

  useEffect(() => {
      ReactGA.pageview(window.location.pathname);
      ReactGA.pageview(window.location.search);
    fetchData();
  }, []);
  name = specificData[0]?.name;
  mineralName = specificData[0]?.mineral;
  console.log(specificData[0]?.name);

  return (
    <div>
      <div className="flex flex-col w-full bg-white">
        <section
          className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}
        >
          <div className="w-full flex py-6 justify-between items-center navbar">
          <Link type="button" to="/dashboard" className="flex justify-end">
          <button type="button" class="text-white bg-gradient-to-r from-[#7FBC41] via-[#F15B26] to-[#7fbc41] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
          <img alt="back-arrow" src={back} width={50} height={60}/>
            </button>
            </Link>
            <span className="mb-4 text-3xl font-extrabold dark:text-white md:text-4xl lg:text-4xl text-white">
              {name + " "}
            </span>
            <span className="mb-4 text-3xl font-extrabold text-white dark:text-white md:text-4xl lg:text-4xl">
              Mineral extracted: {" " + mineralName}
            </span>
            
          </div>
        </section>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-10 my-5 gap-4">
                {specificData.map((element) => (
                  <div className="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <div className="flex items-center justify-between mb-4">
                      <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                        {element?.year}
                      </h5>
                    </div>
                    <div className="flow-root">
                      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                Gross Revenues
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              US${" "}
                              {Number(element?.grossRevenues).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                Net Profits
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              US${" "}
                              {Number(element?.netProfit).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "---"}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                CSR
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              US${" "}
                              {Number(element?.CSR).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || "---"}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                CSR Percent
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {element?.CSRpercent || "--"}
                            </div>
                          </div>
                        </li>
                        <li className="py-3 sm:py-4">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                Ore Outputs
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {Number(element?.oreOutputs).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "---"}{" "}
                              {" " + element?.oreUnits}
                            </div>
                          </div>
                        </li>
                        <li className="pt-3 pb-0 sm:pt-4">
                          <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                Total Tax
                              </p>
                            </div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              US${" "}
                              {Number(element?.totalTax).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "---"}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
