import React from 'react'
import { useNavigate } from 'react-router-dom';
import styles from '../../style';

function DiamondDatagrid(props) {
 // console.log(props)
  let diamondData = [];
  const grossRevenues = [];
  const netProfits = [];
  const totalTaxes = [];
  const totalCSR = [];
  const CSRpercents = [];
  const outputs = [];

  diamondData = props?.parsedDiamondData;

  diamondData?.map(a=>{
        //mines.push(a.number_of_mines)
        grossRevenues.push(Number(a.grossRevenues))
        netProfits.push(Number(a.netProfit))
        totalTaxes.push(Number(a.totalTax))
        totalCSR.push(Number(a.CSR))
        CSRpercents.push(Number(a.CSRpercent))
        outputs.push(Number(a.oreOutputs))
        return a
      })


    const navigation = useNavigate();

    const grossRevenue = grossRevenues.reduce(add, 0); // with initial value to avoid when the array is empty
    function add(accumulator, a) {
      return accumulator + a;
    }
    
    const netProfit = netProfits.reduce(add, 0); // with initial value to avoid when the array is empty
    
    const totalTax = totalTaxes.reduce(add, 0); // with initial value to avoid when the array is empty
    
    const totalCsr = totalCSR.reduce(add, 0); // with initial value to avoid when the array is empty
    
    const totalCSRpercent = CSRpercents.reduce(add, 0); // with initial value to avoid when the array is empty
    
    const totalOreOutput = outputs.reduce(add, 0); // with initial value to avoid when the array is empty


  return (
    <div className="flex flex-col">
        <p className={`${styles.paragraph} m-2`}>
            <span className="text-white text-3xl">Mining Companies</span> 
  </p>
  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Mining Company
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Mineral Output                  
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gross Revenues
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Net Profits
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total Tax
                  </th>      
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    CSR
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    CSR Percent
                  </th>            
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {diamondData?.map(person => (
                  <tr className='cursor-pointer' key={person?.id} onClick={() => {navigation(`/detail/${person.key}`)}}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{person?.name}</div>
                          {/*<div className="text-sm text-gray-500">{person.email}</div>*/}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{person?.type||"---"}</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{Number(person?.oreOutputs).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})||"---"} Carats</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className={`text-sm ${Number(person?.grossRevenues)<0?"text-red-500":"text-gray-900"}`}>US$ {Number(person?.grossRevenues).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})||"---"}</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className={`text-sm ${Number(person?.netProfit)<0?"text-red-500":"text-gray-900"}`}>US$ {Number(person?.netProfit).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})||"---"}</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">US$ {Number(person?.totalTax).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})||"---"}</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">US$ {Number(person?.CSR).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})||"---"}</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>*/}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{Number(person?.CSRpercent).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})||"---"} %</div>
                      {/*<div className="text-sm text-gray-500">{person.department}</div>*/}
                    </td>
                    
                  </tr>
                ))}
              </tbody>
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    TOTALS
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    
                  </th>
                  
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${totalOreOutput<0?"text-red-500":"text-green-500"} uppercase whitespace-nowrap`}
                  >
                    {totalOreOutput.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})} Carats              
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${grossRevenue<0?"text-red-500":"text-green-500"} uppercase whitespace-nowrap`}
                  >
                    US$ {grossRevenue.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${netProfit<0?"text-red-500":"text-green-500"} uppercase whitespace-nowrap`}
                  >
                    US$ {netProfit.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${totalTax<0?"text-red-500":"text-green-500"} uppercase whitespace-nowrap`}
                  >
                    US$ {totalTax.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </th>      
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${totalCsr<0?"text-red-500":"text-green-500"} uppercase whitespace-nowrap`}
                  >
                    US$ {totalCsr.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </th>
                  <th
                    scope="col"
                    className={`px-6 py-3 text-left text-xs font-medium ${totalCSRpercent<0?"text-red-500":"text-gray-500"} uppercase whitespace-nowrap`}
                  >
                    {totalCSRpercent.toFixed(2)} %
                  </th>
                              
                </tr>
              </thead>
              
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiamondDatagrid