import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import TotalTax from './TotalTaxes/TotalTax'
import styles from '../style'
import ReactGA from 'react-ga'

function TotalTaxPage() {
  const TRACKING_ID = "G-TN1N98RRNV";
  ReactGA.initialize(TRACKING_ID);
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
    ReactGA.pageview(window.location.search);
  },[])
  return (
    <div className="flex flex-col w-full bg-white">
    <section
      className={`${styles.flexCenter} my-5 ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}
    >
      <div className="flex-1 flex flex-col">
        <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-4xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
            Total Taxes
          </span>
        </h1>
      </div>
    </section>
              <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-cta"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  to="/mineral-output"
                 className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" ariaCurrent="page"
                >
                  Mineral Output
                </Link>
              </li>
              <li>
                <Link
                  to="/gross-revenue"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                  Gross Revenue
                </Link>
              </li>
              <li>
                <Link
                  to="/net-profit"
                  className="block py-2 pl-3 pr-4 text-gray-900 bg-blue-700 rounded md:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:text-blue-500"
                  
                >
                  Net Profit
                </Link>
              </li>
              <li>
                <Link
                  to="/total-taxes"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  Total Tax
                </Link>
              </li>
              <li>
                <Link
                  to="/csr"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-[#7fbc41] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                >
                  CSR
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </nav>
        <section id="oreoutput">
        <TotalTax />
      </section>
    </div>
  )
}

export default TotalTaxPage