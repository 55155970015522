import React, { useEffect, useState } from "react";
import styles from "../style";
import { pdfReader } from "../assets";
import Papa from "papaparse";
import axios from "../axios";
import ReactGA from 'react-ga';

function Report() {
  const [parsedData, setParsedData] = useState([]);
  const [report, setReport] = useState([]);
  const [visible, setVisible] = useState(6);
  let specificData = [];


  const TRACKING_ID = "G-TN1N98RRNV";
  ReactGA.initialize(TRACKING_ID);
  
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 3);
  };

  const fetchData = () => {
    Papa.parse(
      `https://raw.githubusercontent.com/SIVIO-Institute/MRMI/main/MRMI%20all%20data.csv`,
      {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedData(results.data);
        },
      }
    );
  };

  const getReport = () => {
    axios
      .get(`/report/`)
      .then((response) => {
        setReport(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  parsedData.forEach((reportDetail) => {
    const packet = {
      sourceName: reportDetail?.sourceName,
      sourceURL: reportDetail?.sourceURL,
    };
    specificData.push(packet);
  });

  useEffect(() => {
      ReactGA.pageview(window.location.pathname);
      ReactGA.pageview(window.location.search);
      fetchData();
      getReport();
  }, []);

  return (
    <div>
      <div className="flex flex-col w-full bg-white text-center">
        <section className={`${styles.flexCenter} ${styles.padding} mt-10 sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
          <div className="flex-1 flex flex-col">
            <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-4xl">
              <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
                Reports
              </span>
            </h1>
            <p className={`text-white mt-5`}>
              Reports for all companies as from the year 2017.
            </p>
          </div>
        </section>

        {report.length > 0 ? (
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mx-auto gap-8 p-10">
            <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg p-10">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {report.slice(0, visible).map((report) => (
                    <div className="block text-center max-w-[22rem] mt-5 rounded-lg transform h-64 bg-[#7fbc41] w-80 transition duration-500 hover:scale-125 hover:bg-[#f15b26] font-poppins">
                      <a href={report?.report_files} target="_blank" rel="noopener noreferrer">
                        <img
                          className="h-auto max-w-full rounded-lg"
                          src={pdfReader}
                          alt=""
                        />
                      </a>
                      <div className="p-6">
                        <a href={report?.report_files} className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50 text-center">
                          {report?.title}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col items-center justify-center mt-10 mb-10">
                  {report.length > visible ? (
                    <button disabled="" type="button" className="py-2.5 px-20 mr-2 text-sm font-medium text-[#F15B26] bg-[#7FBC41] rounded border border-green-400 hover:bg-[#F15B26] hover:text-[#7FBC41] focus:z-10 focus:ring-4 focus:outline-none focus:dark:text-gray-600 focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                      onClick={showMoreItems}>
                      <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"></path>
                      </svg>
                      Load more...
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <section className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
            <div className="flex-1 flex flex-col">
              <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-5xl">
                <span className="text-transparent bg-clip-text bg-gradient-to-r to-[#7FBC41] from-[#7FBC41]">
                  No Reports Available
                </span>
              </h1>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default Report;