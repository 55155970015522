import { facebook,email, linkedin, twitter, send, shield, star  } from "../assets";

export const navLinks = [
  {
    id: "",
    title: "Home",
  },
  {
    id:"about",
    title:"About"
  },
  {
    id: "dashboard",
    title: "Dashboard",
  },
  {
    id: "report",
    title:"Reports"
  }
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Rewards",
    content:
      "The best credit cards offer some tantalizing combinations of promotions and prizes",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    content:
      "We take proactive steps make sure your information and transactions are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Balance Transfer",
    content:
      "A balance transfer credit card can save you a lot of money in interest charges.",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Mining Companies",
    value: "10",
  },
  {
    id: "stats-2",
    title: "Total Tax",
    value: "US$ 583M",
  },
  {
    id: "stats-3",
    title: "Revenue Generated",
    value: "US$ 5.5B",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "About",
        link: "/about",
      },
      {
        name: "Dashboard",
        link: "/dashboard",
      },
      {
        name: "Reports",
        link: "/report",
      }
    ],
  }
];


export const socialMedia = [
  {
    id: "social-media-1",
    icon: email,
    link: "mailto:info@sivioinstitute.org",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/sivioinstitute",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://twitter.com/sivioinstitute",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/company/sivio-institute/",
  },
];
