import React, {useEffect} from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styles from "./style";
import { Footer, Navbar, Hero } from "./components";
import Dashboard from "./components/Dashboard";
import Report from "./components/Report";
import About from "./components/About";
import Detail from "./components/Detail";
import ReactGA from 'react-ga';
import GrossRevenuePage from "./components/GrossRevenuePage";
import NetProfitPage from "./components/NetProfitPage";
import TotalTaxPage from "./components/TotalTaxPage";
import CSRPage from "./components/CSRPage";
import OutputPage from "./components/OutputPage";

const App = () => {

  const TRACKING_ID = "G-TN1N98RRNV";
  ReactGA.initialize(TRACKING_ID);

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
    ReactGA.pageview(window.location.search);
  },[])
  return (
  <div className="bg-tertiary min-h-[100vh] w-full">
    
    
    <BrowserRouter>
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <section className={`${styles.boxWidth}`} id="top">
        <Navbar />
      </section>
    </div>
        <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<About />} />
        <Route path="/report" element={<Report />} />
        <Route path="/gross-revenue" element={<GrossRevenuePage />} />
        <Route path="/net-profit" element={<NetProfitPage />} />
        <Route path="/total-taxes" element={<TotalTaxPage />} />
        <Route path="/csr" element={<CSRPage />} />
        <Route path="/mineral-output" element={<OutputPage />} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/detail/:id" element={<Detail/>} />
        </Routes>
        <div className={`bg-tertiary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        
        <Footer />
      </div>
    </div>
      </BrowserRouter>
  </div>
)}

export default App;
